import { render, staticRenderFns } from "./AccessTabWrapper.vue?vue&type=template&id=5bef0c90&scoped=true"
import script from "./AccessTabWrapper.vue?vue&type=script&lang=js"
export * from "./AccessTabWrapper.vue?vue&type=script&lang=js"
import style0 from "./AccessTabWrapper.vue?vue&type=style&index=0&id=5bef0c90&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bef0c90",
  null
  
)

export default component.exports