<template>
    <div class="p-2" style="background-color: #fff; color: #696F8C;">
        <div>
            <p class="font-weight-bold">Assign access level</p>
            <multi-select-component
            @update:selectedItems="handleSelectedItems"
            :items="assignName" 
            :selected-items="selectedItems"
            ></multi-select-component>
        </div>
        <access-buttons
        :selected-assignee-ids="selectedAssigneeIds"
        @cancel="cancel()"
        ></access-buttons>
    </div>
</template>

<script>
import multiSelectComponent from './MultiSelectComponent.vue';
import AccessButtons from './AccessButtons.vue';

export default {
    components: {
        multiSelectComponent,
        AccessButtons,
    },
    data() {
        return {
            selectedAssigneeIds: [],
            selectedItems: [],
        }
    },
    created() {
        this.$store.dispatch(
            "employees/fetchEmployeesIdsNames",
        )
    },
    computed: {
        listAccess() {
            return this.$store.state?.employees?.employeeIdsNames;
        },
        assignName() {
            return this.listAccess.map(access => access?.displayName);
        },
    },
    methods: {
        handleSelectedItems(selectedItems) {
            this.selectedAssigneeIds = selectedItems.map(item => item?.id);
            this.$store.commit("access/SET_USER_IDS", this?.selectedAssigneeIds);
        },
        cancel() {
            this.selectedItems = [];
        }
    },
}
</script>
