<template>
  <v-autocomplete
    style="width: 500px"
    v-model="localSelectedItems"
    :items="items"
    label="Select Employees"
    multiple
    @change="emitSelectedItems"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localSelectedItems: this.selectedItems,
    };
  },
  computed: {
    getItems() {
      return this.$store.state.employees.employeeIdsNames;
    },
  },
  watch: {
    selectedItems: {
      immediate: true,
      handler(newVal) {
        this.localSelectedItems = newVal;
      }
    }
  },
    methods: {
      emitSelectedItems() {
        const selectedItems = this.localSelectedItems.map((item) => {
          const selectedItem = this.getItems.find(
            (i) => i.displayName === item
          );
          return { id: selectedItem?.userId, name: item };
        });
        this.$emit("update:selectedItems", selectedItems);
      },
    },
  created() {
    this.$store.dispatch("access/listAccessLevel", { page: 1 });
    this.$store.dispatch(
            "employees/fetchEmployeesIdsNames",
        );
  },
};
</script>
