<template>
    <div style="background-color: #fff; border-radius: 8px;">
        <access-tab-wrapper></access-tab-wrapper>
    </div>
</template>

<script>
import AccessTabWrapper from '../layouts/AccessTabWrapper.vue';
export default {
    components: {
        AccessTabWrapper,
    },
}
</script>
